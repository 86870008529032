import React from "react";
import { useLoaderData, useNavigation } from "react-router-dom";

export const Dashboard = () => {
  const dogUrl = useLoaderData() as string;
  const navigation = useNavigation();

  if (navigation.state === 'loading') {
    return <div>Loading...</div>;
  }
  return (
    <div>
      Dashboard Page
      <img src={dogUrl} alt="random" />
    </div>
  );
};

export const dataLoader = async (): Promise<string> => {
  const res = await fetch("https://random.dog/woof.json");
  const dog = await res.json();

  return dog.url;
};

export default Dashboard;
