import React from 'react';
import Logo from '../imgs/logo.jpg';

export default function Navbar() {
  return (
    <nav
      className="navbar navbar-light navbar-expand-md py-1"
      style={{ boxShadow: "0px 0px", background: "#d9d9d9" }}
    >
      <div className="container">
        <a className="navbar-brand d-flex align-items-center" href="/">
          <span
            className="bs-icon-md bs-icon-primary d-flex justify-content-center align-items-center me-2 bs-icon"
            style={{ marginRight: 8 }}
          >
            <img src={Logo} alt='logo-icon' width={96} height={70} />
          </span>
          <span style={{ fontSize: 20 }}>
            KITUTU CHACHE SOUTH BURSARY FUND | Empowering Dreams
          </span>
        </a>
        <button
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navcol-2"
        >
          <span className="visually-hidden">Toggle navigation</span>
          <span className="navbar-toggler-icon" />
        </button>
        <div id="navcol-2" className="collapse navbar-collapse">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a
                className="nav-link"
                href="mailto:bursary@kcsngcdf.go.ke?subject=Inquiry on Bursary"
              >
                Email: bursary@kcsngcdf.go.ke
              </a>
              <a className="nav-link" href="/">
                Bursary Support: Click Here
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
