import React from "react";
import Navbar from "./Navbar";
import Logo from "../imgs/logo.jpg";

const LoginPage = () => {
  return (
    <section className="py-4 py-xl-5" style={{ background: "#3ba6df" }}>
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-md-6 col-xl-4">
            <div className="card mb-5">
              <div className="card-body d-flex flex-column align-items-center">
                <div className="bs-icon-xl bs-icon-circle bs-icon my-2">
                  <img src={Logo} alt="Logo" width={74} height={62} />
                </div>
                <span style={{ fontSize: 14, marginBottom: 25 }}>Admin Portal</span>
                {/* <hr style={{ width: 290 }} /> */}
                <form className="text-center" method="post">
                  <div className="mb-3" style={{ display: "flex" }}>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      placeholder="Your Email Address"
                    />
                  </div>
                  <div className="mb-3">
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      placeholder="Enter Password"
                    />
                  </div>
                  <div className="d-flex flex-row justify-content-between mb-3">
                    <div className="form-check">
                      <input
                        id="formCheck-1"
                        className="form-check-input"
                        type="checkbox"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="formCheck-1"
                        style={{ fontSize: 12 }}
                      >
                        Remember Me
                      </label>
                    </div>
                    <label
                      className="form-label"
                      style={{ fontSize: 12, color: "#3ba6df" }}
                    >
                      Forgot password?
                    </label>
                  </div>
                  <div className="mb-1">
                    <button
                      className="btn btn-primary d-block w-100"
                      type="submit"
                      style={{ background: "#47ae6a" }}
                    >
                      Login
                    </button>
                  </div>
                  <label
                    className="form-label"
                    style={{ fontSize: 12, color: "#3ba6df" }}
                  >
                    <hr style={{ width: 270 }} />
                    New user? Please contact ICT for registration and login
                    credentials.
                  </label>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default function Login() {
  return (
    <>
      <Navbar />
      <LoginPage />
    </>
  );
}
