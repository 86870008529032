import React from "react";
import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import Login from "./pages/Login";
import Dashboard, { dataLoader } from "./pages/Dashboard";

// import { initializeApp } from "firebase/app";
// import { getFirestore } from "firebase/firestore";
// import firebaseConfig from "./firebaseConfig";

// const firebaseApp = initializeApp(firebaseConfig);
// const db = getFirestore(firebaseApp);

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Login />}>
        <Route path="dashboard" element={<Dashboard />} loader={dataLoader} />
        <Route index path="/login" element={<Login />} />
      </Route>
    )
  );

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
